import { twMerge } from "tailwind-merge";

interface AssProps<T extends React.ElementType> {
  as?: T;
  children?: React.ReactNode;
}

type Props = {
  alert?: React.ReactNode;
  col1?: React.ReactNode;
  col2?: React.ReactNode;
  cta?: React.ReactNode;
  leftBlue?: React.ReactNode;
  largeCol2?: boolean;
  fixedCta?: true;
  className?: string;
  verticallyCentered?: boolean;
};

export const TwoColumns = <T extends React.ElementType = "div">({
  alert,
  col1,
  col2,
  cta,
  leftBlue,
  largeCol2,
  fixedCta,
  className,
  verticallyCentered,
  as,
}: Props &
  AssProps<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof AssProps<T>>) => {
  const As = as || "div";

  const getDesktopGridWidth = () => {
    if (leftBlue) {
      return "md:grid-cols-1";
    } else {
      return largeCol2 ? "md:grid-cols-[1fr_2fr]" : "md:grid-cols-2";
    }
  };

  const gridClassNames = `shrink grid md:self-justify-center max-md:grow ${getDesktopGridWidth()} grid-cols-1 gap-4 ${getGridHeight(
    { alert, col2, cta }
  )}`;
  return (
    <As
      className={twMerge(
        `flex max-md:grow w-full ${
          leftBlue
            ? `items-stretch`
            : `justify-center max-w-6xl ${
                verticallyCentered ? "md:items-center" : ""
              }`
        }`,
        verticallyCentered ? "md:grow" : "",
        className
      )}
    >
      {leftBlue && (
        <div className="max-md:hidden bg-blue-50 row-span-2 md:flex-[0_1_528px] sticky top-0 h-[100vh] py-24">
          {leftBlue}
        </div>
      )}
      <div
        className={twMerge(
          gridClassNames,
          `grow md:mx-4 ${
            leftBlue
              ? `${
                  verticallyCentered ? "md:self-center" : "md:self-start"
                } md:mx-16 md:flex-[1_1_528px]`
              : ``
          }`
        )}
      >
        <div className="p-[3px] md:col-start-1 md:row-start-1 md:col-span-2  max-md:mx-4 md:mb-8">
          {alert}
        </div>
        <div
          className={`p-[3px] md:col-start-1 ${getCol1RowPosition({
            alert,
          })} max-md:mx-4 ${leftBlue ? "md:hidden" : ""}`}
        >
          {col1}
        </div>
        {col2 && (
          <div
            className={`p-[3px] ${getCol2ColStart({
              leftBlue,
            })} ${getCol1RowPosition({
              alert,
            })} max-md:mx-4`}
          >
            {col2}
          </div>
        )}
        <div
          className={`p-[3px] ${getCol2ColStart({
            leftBlue,
          })} max-md:mx-4 max-md:self-end ${
            fixedCta === true
              ? "max-md:sticky max-md:bottom-0 max-md:bg-white"
              : ""
          } ${getCtaPosition({
            alert,
            col2,
          })}`}
        >
          <div
            className={`h-[20px] w-full absolute top-[-20px] bg-gradient-to-t from-white`}
          />
          {cta}
        </div>
      </div>
    </As>
  );
};

const getCtaPosition = ({ alert, col2 }: Props) => {
  if (alert && col2) {
    return "md:row-start-3";
  } else if (alert && !col2) {
    return "md:row-start-2";
  } else if (!alert && col2) {
    return "md:row-start-2";
  } else {
    return "md:row-start-1";
  }
};
const getCol1RowPosition = ({ alert, leftBlue }: Props) => {
  const length = [alert].filter(Boolean).length;

  if (length === 1) {
    return "md:row-start-2";
  } else {
    return "md:row-start-1";
  }
};

const getCol2ColStart = ({ leftBlue }: Props) => {
  if (leftBlue) {
    return "md:col-start-1";
  } else {
    return "md:col-start-2";
  }
};

const getGridHeight = ({ alert, col2, cta }: Props) => {
  const length = [cta, col2, alert].filter(Boolean).length;
  if (length === 1) {
    return "md:grid-rows-1";
  } else if (length === 2) {
    return "md:grid-rows-[auto_auto]";
  } else return "md:grid-rows-[auto_auto_auto]";
};
